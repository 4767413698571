<template>
    <div class="box">
        <div class="item">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="(item,index) in routerUrl" :to="{path:item.path,query:{id:item.id,flag:true}}" :key="index">{{item.name}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item :to="{ path: '/' }">活动管理</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        routerUrl:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{
        }
    },
    
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 56px;
    border-top: 1px solid #eee;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item{
        width: 1200px;
        height: 56px;
        padding: 0 20px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>