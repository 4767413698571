<template>
  <div class="conmyBox">
      <commonHeader :current-index="1"></commonHeader>
      <crumbs :routerUrl="routerUrl"></crumbs>
      <div class="contation">
          <div class="contationItem">
                <introduction @tabVideo="myPlay" ref="childPlay" :domainObj="domainObj" :recommendList="recommendList"></introduction>
                <brief :text="text"></brief>
          </div>
      </div>
      <commonFooter></commonFooter>
  </div>
</template>

<script>
import { getInfoCustomer,getUserByPhone } from '@/common/public.js'
import commonHeader from '@/components/commonHeader.vue'
import crumbs from '@/components/course/crumbs'
import introduction from '@/components/course/introduction'
import brief from '@/components/course/brief'
import commonFooter from '@/components/commonFooter.vue'

export default {
    components:{ commonHeader,crumbs,introduction,brief,commonFooter },
    data(){
        return{
            id:'',//视频详情id
            name:'',//传递的name
            titleId:'',// 面包屑导航id
            flag:false,//判断执行播放事件顺序的方法
            routerUrl:[
                {name:'全部课程',path:''},
                {name:'课程详情',path:''}
            ],//面包屑导航
            recommendList:[],//右边视频的列表数组
            text:null,//简介
            domainObj:{},//请求的数据
            // isFree:Number,//课程是否是免费的
            // isBuy:Number,//是否购买了该课程
        }
    },
    updata(){
        console.log('qweqwe')
    },
    watch:{
        domainObj(newMsg,oldMsg){
            console.log(newMsg,oldMsg,'asdasd')
            this.domainObj = newMsg;
        }
    },
    created(){
        let flag = window.localStorage.getItem('tltoken')
        if(flag){
            this.getPhoneUser()
        }
        if(this.$route.query.id && this.$route.query.id !== undefined){
            this.id = this.$route.query.id;
        }else{
            this.id =window.localStorage.getItem('videoId');
        }
        this.name = this.$route.query.name;
        this.titleId = this.$route.query.ids;
        let pathUrl = this.$route.path;
        if(pathUrl == '/playdetails'){
            if(this.name == '全部'){
                this.routerUrl = [
                    {name:'全部课程',path:'/courseList',id:''},
                    {name:'课程详情',path:''},
                ]
            }else{
                if(this.name){
                    this.routerUrl = [
                        {name:'全部课程',path:'/courseList',id:''},
                        {name:this.name,path:'/courseList',id:this.titleId},
                        {name:'课程详情',path:''},
                    ]
                }else{
                    this.routerUrl = [
                        {name:'全部课程',path:'/index'},
                        {name:'课程详情',path:''},
                    ]
                }
                
            }
            
        }
    },
    methods:{
        //根据手机号查询用户的信息
        getPhoneUser(){
            let data = {
                phone:this.$store.state.user.phone
            };
            getUserByPhone(data).then(res=>{
                console.log(res,'datadata')
                this.$store.commit('playDetails',res.result.domain)
            })
        },
        //子组件播放视频事件
        myPlay(obj){
            this.id = obj.id;
            console.log(this.id,'子组件传递父组件的值');
            if(obj.flag && obj.flag== true){
                this.flag = obj.flag;
            }
            this.getDetil()
        },
        //视频详情
        getDetil(){ 
            let userId = null;
            if(this.$store.state.userid){
                userId = this.$store.state.userid
            }else{
                userId = 0;
            }
            let data = {
                userId,
                id:this.id
            };
            getInfoCustomer(data).then(res=>{
                this.recommendList = res.result.domain.recommendList;
                this.$store.commit('getVideo',res.result.domain.id)
                this.domainObj = res.result.domain;
                this.text = res.result.domain.content.replace(/↵/g,"/n");
                if(this.flag){
                    console.log('点击右边子菜单触发该事件')
                    this.$refs.childPlay.playBuy()
                    this.flag = false;
                }
            })
        },
    },
    mounted(){
        this.getDetil()
    }
}
</script>

<style scoped lang="less">
.conmyBox{
    width: 100%;
    .contation{
        display: flex;
        flex-direction: row;
        justify-content: center; 
        align-items: center;
        width: 100%;
        background: #F7F7F7;
        .contationItem{
            width: 1200px;
        }
    }
}
</style>