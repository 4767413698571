<template>
    <div class="box">
        <div class="title">
            <span>简介</span>
        </div>
        <div class="text">
            <p v-html="text"></p>
        </div>
    </div>
</template>

<script>
export default {
    props:['text']
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    padding: 0 23px;
    background: #fff;
    margin: 0 0 70px 0;
    .title{
        width: 100%;
        height: 45px;
        // background: red;
        border-bottom: 1px solid #EEEEEE;
        span{
            font-size: 16px;
            font-weight: bold;
            line-height: 32px;
            color: #097638;
            padding: 12px 0;
            border-bottom: 4px solid #FED23E;
        }
    }
    .text{
        width: 100%;
        padding: 40px 0 10px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #333333;
        //解析换行符号代码
         white-space: pre-line;
    }
}
</style>