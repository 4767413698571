<template>
    <div class="box">
        <div class="videoList">
            <div class="left">
                <div id="video"></div>
                <div class="pouse" v-if="playBtn">
                    <img class="bcgPic" :src="domainObj.coverUrl" alt="">
                    <img class="icoo" src="@/assets/img/pouse.png" alt="" @click="playBuy">
                </div>
                <div class="mask" v-if="purchaseBtn">
                    <div class="end">试看结束</div>
                    <div class="cen">查看更多课程请购买套餐</div>
                    <div class="btnImage" @click="toBuyDetail">
                        <img src="@/assets/img/playVip.png" alt="">
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="rightTitle">相关课程</div>
                <div class="courseList">
                    <div class="item" v-for="(item,index) in recommendList" :key="index" @click="tabDetail(item.id)">
                        <div class="img">
                            <img :src="item.coverUrl" alt="">
                            <span>{{item.publishTime | toTime(item.publishTime)}}</span>
                        </div>
                        <div class="courseTitle">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="botBuy">
            <div class="left">
                <div class="title">
                    {{domainObj.name}}
                </div>
                <div class="user">
                    <span>作者：&nbsp;&nbsp; 发布时间：{{domainObj.publishTime | toTime(domainObj.publishTime)}}</span>
                    <span>累计观看 &nbsp;&nbsp;&nbsp; {{domainObj.watchNum}}次</span>
                </div>
            </div>
            <div class="right">
                <div v-if="$store.state.user.surplusTimes<=0" class="btnVip"  @click="toBuyDetail">购买VIP</div>
                <div v-if="domainObj.isBuy !== 1" class="Rightbtn" @click="playVideo">免费试看</div>
            </div>
        </div>
    </div>
</template>

<script>
let conVideo = null;
import { getVideoHour,videoCourse } from '@/common/public.js'
export default {
    props:{
        recommendList:{
            type:Array,
            default:()=>[]
        },
        domainObj:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            conVideo:null,
            purchaseBtn:false,//控制结束后是否显示购买会员的弹框
            playBtn:true,
            auto:false,//控制视频是否自动播放
            playAuth:'',
            videoId:'',
            mtToken:'',//token认证
            plagFlag:false,
            isTry:'',// 1表示试看0 表示完整地址
            freeWatch:'',//freeWatch==0 代表观看完整视频，1代表观看试看视频。
        }
    },
    methods:{
        //切换视频
        tabDetail(id){
            this.auto = true;
            // setTimeout(() => {
                if(this.plagFlag){
                    conVideo.dispose()
                }
                this.$emit('tabVideo',{id,flag:true})
            // }, 100);
        },
        //点击免费试看触发
        playVideo(){
            this.freeWatch = 1;
            this.getVideoPlay()
        },
        //播放信息接口
        getVideoPlay(){
            console.log(this.domainObj.id,'idididid')
            let data = {
                id:this.$store.state.videoId,
                userId:this.$store.state.userid,
                freeWatch:this.freeWatch
            };
            videoCourse(data).then(res=>{
                this.playAuth = res.result.domain.playAuth;
                this.videoId = res.result.domain.videoId;
                this.isTry = res.result.domain.isTry;
                this.mtToken = res.result.domain.mtsHlsUriToken;
                this.statePlay()
            })
        },
        //判断是否是免费的
        isAdmission(){
            let tokenFlag = window.localStorage.getItem('tltoken');
            //0是付费 1是免费
            if(this.domainObj.isFree){
                this.getVideoPlay()
            }else{
                //判断有没有登录
                if(tokenFlag){
                    //判断有没有买该课程  0 是没有买 1是买了该课程
                    if(this.domainObj.isBuy){
                        //1 买了可以观看了
                        this.getVideoPlay()
                    }else{
                        //没有买判断有没有次数
                        if(this.$store.state.user.surplusTimes && this.$store.state.user.surplusTimes>0){
                            //有次数并且买了该课程,提示消耗次数
                            this.isBuyVideo()
                        }else{
                            //没有次数了提示他去购买套餐补充次数
                            this.$message({
                                showClose: true,
                                message: '您没有套餐次数了，请去购买',
                                type: 'warning'
                            });
                        }
                    }
                }else{
                    //没有登录的情况下去跳转到登陆页面
                    this.$router.push({
                        path:'/login'
                    })
                }
            }
        },
        //点击按钮播放
        playBuy(){
            this.freeWatch = 0;
            this.isAdmission()
        },
        //判断是否购买的逻辑
        isBuyVideo(){
            let data = {
                id:this.$store.state.user.id
            };
            getVideoHour(data).then(res=>{
                if(res.code == 200){
                    this.$confirm('播放该视频会消耗一次套餐次数, 有效期为在扣除之后的'+res.result.domain.videoHour+'小时有效,是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                    this.getVideoPlay()
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '您已取消观看该视频'
                        });          
                    });
                }
            })
        },
        //跳转到购买页面
        toBuyDetail(){
            this.$router.push({
                path:'/paymentInfor'
            })
        },
        //视频创建播放器方法
        statePlay(){
            let auto = this.auto;
            conVideo =  new window.Aliplayer({
                id:'video',
                width:'886px',
                height:'498px',
                autoplay: auto,//是否自动播放
                isLive: false,//是否是直播
                enableSystemMenu:true,//是否允许系统右键菜单显示
                playsinline:true,//H5是否内置播放
                // definition:'FD,LD,SD,HD',//清晰度切换
                defaultDefinition:'SD',//默认显示的清晰度
                vid: this.videoId,//视频的videoId
                playauth:this.playAuth,
                playConfig:{
                    "MtsHlsUriToken":this.mtToken
                },
                "encryptType": 1,
                format:"m3u8"//播放格式是m3u8
            },(player)=>{
                this.myPlaying()
                player.on('ended',()=>{
                    //监听视频播放结束
                    // this.playBtn = false;
                    if(this.isTry == 1){
                        this.purchaseBtn = true;
                    }else{
                        this.purchaseBtn = false;
                    }
                });
                player.on('pause',()=>{
                    //监听视频暂停状态
                });
                player.on('play',()=>{
                    //监听点击播放
                    this.purchaseBtn = false;
                    this.plagFlag = true;
                });
            })
        },
        //视频播放方法
        myPlaying(){
            this.playBtn = false;
            conVideo.play()
        }
    }
}
</script>

<style scped lang="less">
.prism-player .prism-big-play-btn {
    z-index: 99!important;
}
.prism-player .prism-setting-list .prism-setting-item[type=quality]{
 display: none;
}
.box{
    width: 100%;
    padding: 35px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .videoList{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left{
            width: 886px;
            height: 498px;
            position: relative;
            // video{
            //     object-fit: fill;
            // }
            .pouse{
                position: absolute;
                width: 886px;
                height: 498px;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,0.4);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                z-index: 999;
                .bcgPic{
                    position: absolute;
                    left: 0;
                    top: 0;
                     width: 886px;
                    height: 498px;
                }
                .icoo{
                    width: 88px;
                    height: 88px;
                    position: absolute;
                    left: 45%;
                    top: 45%;
                    z-index: 999;
                }
            }
            .mask{
                position: absolute;
                width: 886px;
                height: 498px;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,0.4);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 400;
                line-height: 48px;
                color: #FFFFFF;
                .btnImage{
                    width: 200px;
                    height: 56px;
                    cursor: pointer;
                    img{
                        width: 200px;
                        height: 56px;
                    }
                }
            }
        }
        .right{
            width: 314px;
            height: 498px;
            background: #fff;
            padding: 12px 13px 12px 18px;
            border: 1px solid #EEEEEE;
            .rightTitle{
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
                color: #333333;
            }
            .courseList::-webkit-scrollbar{
                display: none;
            }
            .courseList{
                width: 100%;
                height: 434px;
                overflow-y: scroll;
                .item{
                    width: 100%;
                    margin-bottom: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    cursor: pointer;
                    .img{
                        width: 107px;
                        height: 60px;
                        position: relative;
                        img{
                            width: 107px;
                            height: 60px;
                            border-radius: 2px;
                        }
                        span{
                            position: absolute;
                            bottom: 4px;
                            left: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            color: #FFFFFF;
                        }
                    }
                    .courseTitle{
                        width: 163px;
                        height: 50px;
                        overflow: hidden;
                        padding: 16px 0 6px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        color: #333333;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                .item:hover{
                    .courseTitle{
                        color: #097638;
                    }
                }
            }
        }
    }
    .botBuy{
        width: 100%;
        height: 90px;
        padding: 10px 23px;
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left{
            width: 750px;
            height: 100%;
            padding: 6px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title{
                width: 100%;
                font-size: 24px;
                font-weight: 400;
                line-height: 33px;
                color: #333333;
            }
            .user{
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #333333;
                span{
                    margin-right: 10px;
                }
            }
        }
        .right{
            width: 420px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            .btnVip{
                width: 200px;
                height: 44px;
                background: #FF8000;
                opacity: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                cursor: pointer;
            }
            .Rightbtn{
                width: 200px;
                height: 44px;
                background: #fff;
                border: 1px solid #097638;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #097638;
                cursor: pointer;
            }
        }
    }
}
</style>